import Tables from "../../reusableComponents/Tables/Table/TableCard";
import React, { useState, useEffect } from 'react';
import { getLibrariansList } from "../../axios-client";
import AddLibrarianDialog from "./AddLibrarianDialog";
import { IconButton } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import EditLibrarianDialog from "./EditLibrarianDialog";
const Librarians = (props) => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openAddLibrarianDialog, setOpenAddLibrarianDialog] = useState(false);
    const [openEditLibrarianDialog, setOpenEditLibrarianDialog] = useState(false);
    const [selectedLibrarian, setSelectedLibrarian] = useState(null);

    const fetchLibrarians = () => {
        setLoading(true);
        getLibrariansList()
            .then(res => {
                const formattedRows = res.data.users.map(librarian => ({
                    "Name": librarian.name,
                    "Email": librarian.email,
                    "Phone": librarian.phone,
                    "Department": librarian.department,
                    "Action":  (
                        <IconButton
                            onClick={() => openEditDialog(librarian)}
                            style={{ cursor: 'pointer' }}
                            size="small"
                        >
                            <Edit />
                        </IconButton>
                    ),
                }));
                setRows(formattedRows);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching librarians:', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchLibrarians();
    }, []);

    const handleOpenDialog = () => {
        setOpenAddLibrarianDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenAddLibrarianDialog(false);
    };
    const openEditDialog = (librarian) => {
        setSelectedLibrarian(librarian);
        setOpenEditLibrarianDialog(true);
    };

    const closeEditDialog = () => {
        setOpenEditLibrarianDialog(false);
        setSelectedLibrarian(null);
    };


    const refreshLibrariansList = () => {
        handleCloseDialog();
        fetchLibrarians();
    };

    return (
        <div>
            <Tables rows={rows} title={"Librarians"} showAddButton={true} ButtonName={"Add Librarian"}
                    onButtonClick={handleOpenDialog} loadingData={loading} />
            {openAddLibrarianDialog && (
                <AddLibrarianDialog
                    open={openAddLibrarianDialog}
                    onClose={handleCloseDialog}
                    onLibrarianAdded={refreshLibrariansList}
                />
            )}
            {openEditLibrarianDialog && (
                <EditLibrarianDialog
                    open={openEditLibrarianDialog}
                    onClose={closeEditDialog}
                    onLibrarianAdded={fetchLibrarians}
                    librarianData={selectedLibrarian}
                />
            )}
        </div>
    );
};

export default Librarians;
