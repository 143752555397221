import Tables from "../../reusableComponents/Tables/Table/TableCard";
import React, { useState, useEffect } from 'react';
import {addDepartment, getDepartementsList} from "../../axios-client";
import AddDepartmentDialog from "./AddDepartmentDialog";

import SnackbarNotification from "../../reusableComponents/Snackbar/SnackbarNotification";

const Departments = (props) => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openAddDepartmentDialog, setOpenAddDepartmentDialog] = useState(false);

    const [notification, setNotification] = useState({show: false, snackbarMessage: '', severity: ""})

    const fetchData = () => {
        getDepartementsList()
            .then(res => {
                const formattedRows = res.data.map(department => ({
                    "Department Name": department.name,
                    "University": department.university.name,
                    "Max Users": department.max_users,
                    "Phone": department.phone,
                    "Action": "Edit",
                }));

                setRows(formattedRows);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching departments:', error);
                setLoading(false);
            });
    }


    useEffect(() => {
      fetchData();
    }, []);

    const handleAddDepartment = async (formData) => {
        const { department, university, phone, maxUsers } = formData;

        const data = {
            name: department,
            university_id: university,
            phone,
            max_users: maxUsers
        };

        try {
            await addDepartment(data);
            fetchData();
            setOpenAddDepartmentDialog(false);
            setNotification({ show: true, snackbarMessage: 'Department added successfully!', severity: "success" });
        } catch (error) {
            console.error('Error adding department:', error);
            setNotification({ show: true, snackbarMessage: 'Failed to add department. Please try again!', severity: "error" });
        }
    };

    const handleOpenDialog = () => {
        setOpenAddDepartmentDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenAddDepartmentDialog(false);
    };

    return (
        <>
            <Tables rows={rows} title={"Departments List"} showAddButton={true} ButtonName={"Add department"}
                    onButtonClick={handleOpenDialog} loadingData={loading} />
            {openAddDepartmentDialog && (
                <AddDepartmentDialog
                    open={openAddDepartmentDialog}
                    onClose={handleCloseDialog}
                    handleAddDepartment={handleAddDepartment}
                />
            )}

               <SnackbarNotification
                   notification={notification}
                   setNotification={setNotification}
               />
        </>
    );

}
export default Departments;