import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import curved0 from "assets/images/curved-images/curved0.jpg";
import Icon from "@mui/material/Icon";
import PropTypes from "prop-types";
import {useSoftUIController} from "../../../../context";
import {setEditing} from "../../../../store/actions/app-actions";

function Header({ info, setInfo }) {
    const [isHovered, setIsHovered] = useState(false);
    const [controller, dispatch] = useSoftUIController();
    const {picture, isEditing} = controller;
    const [profileImage, setProfileImage] = useState(picture);
    const [localImage, setLocalImage] = useState(false);



    const handleImageHover = () => {
        setIsHovered(true);
    };

    const handleImageMouseLeave = () => {
        setIsHovered(false);
    };

    const handleImageClick = () => {
        document.getElementById("profileImageInput").click();
    };


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const imageUrl = URL.createObjectURL(file);

            setLocalImage(true);
            setProfileImage(imageUrl);
            setEditing(dispatch, true);


            setInfo(prevState => ({
                ...prevState,
                profile_picture: file,
            }));

        }
    };


    useEffect(() => {
        if (!localImage) {
            setProfileImage(picture);
        }
    }, [picture, localImage]);




    return (
        <SoftBox position="relative">
            <SoftBox
                display="flex"
                alignItems="center"
                position="relative"
                minHeight="18.75rem"
                borderRadius="xl"
                sx={{
                    backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.info.main, 0.6),
                            rgba(gradients.info.state, 0.6)
                        )}, url(${curved0})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "hidden",
                }}
            />
            <Card
                sx={{
                    backdropFilter: `saturate(200%) blur(30px)`,
                    backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
                    boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                    position: "relative",
                    mt: -8,
                    mx: 3,
                    py: 2,
                    px: 2,
                }}
            >
                <Grid container spacing={3} alignItems="center">
                    <Grid item>
                        <div
                            onMouseEnter={handleImageHover}
                            onMouseLeave={handleImageMouseLeave}
                            onClick={handleImageClick}
                            style={{ cursor: "pointer", position: "relative" }}
                        >
                            <div style={{
                                position: 'relative',
                                display: 'inline-block'
                            }}>
                                <SoftAvatar
                                    alt="profile-image"
                                    variant="rounded"
                                    size="xl"
                                    shadow="sm"
                                    src={profileImage}
                                    style={{
                                        filter: isHovered ? 'brightness(0.5)' : 'none',
                                        transition: 'filter 0.3s ease'
                                    }}
                                />
                                <input
                                    type="file"
                                    id="profileImageInput"
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    onChange={handleImageChange}
                                />
                                {isHovered && (
                                    <div style={{
                                        position: 'absolute',
                                        top: '25%',
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        borderRadius: '0 0 19% 19%',
                                        transition: 'opacity 0.3s ease',
                                        clipPath: 'inset(30% 0 0 0)',
                                    }}>
                                        <SoftTypography
                                            style={{
                                                fontSize: '0.425rem',
                                                position: 'absolute',
                                                bottom: '2%',
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.3rem'
                                            }}
                                            color="white"
                                            fontWeight="regular"
                                        >
                                            <Icon style={{fontSize: '1.2rem'}}>photo_camera</Icon>
                                            Upload Profile Picture
                                        </SoftTypography>
                                    </div>

                                )}
                            </div>
                        </div>
                    </Grid>
                    <Grid item>
                        <SoftBox height="100%" mt={0.5} lineHeight={1}>
                            <SoftTypography variant="h5" fontWeight="medium">
                                {info.name}
                            </SoftTypography>
                        </SoftBox>
                    </Grid>
                </Grid>
            </Card>
        </SoftBox>
    );
}

Header.propTypes = {
    info: PropTypes.objectOf(PropTypes.string),
    setInfo: PropTypes.func
}

export default Header;
