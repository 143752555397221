import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SuiButton from "components/SoftButton";
import { MdRssFeed } from "react-icons/md";
import { IoIosAdd } from "react-icons/io";
import DashboardLayout from "reusableComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "reusableComponents/Navbars/DashboardNavbar";
import Footer from "reusableComponents/Footer";
import CardComponent from "reusableComponents/Tables/Card";
import { getArchives, getArchivesById } from "axios-client";
import { formatDate, formatDataToUrlQueryParams } from "utlis/generalUtils";
import DigitizeArticleDialog from "./DigitizeArticleDialog";
import { digitize } from "../../axios-client";
import SnackbarNotification from "../../reusableComponents/Snackbar/SnackbarNotification";
import {useSoftUIController} from "../../context";
import InputAdornment from "@mui/material/InputAdornment";

const publications = () => {
  // State declarations
  const [controller] = useSoftUIController();
  const { role } = controller;
  const rolesAllowed = ['Librarian', 'Researcher'];
  const [rows, setRows] = useState([]);
  const [articleCount, setArticleCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentFilter, setCurrentFilter] = useState("All Fields");
  const [searchText, setSearchText] = useState("");
  const [isDigitizing, setIsDigitizing] = useState(false);
  const [openDigitizerDialog, setOpenDigitizerDialog] = useState(false);
  const [notification, setNotification] = useState({
    show: false,
    snackbarMessage: "",
    severity: "",
  });
  const [filterData, setFilterData] = useState({
    page: 1,
    size: 10,
    Title: "",
    Author: "",
    Date: "",
    Language: "",
    SubjectsAndKeywords: "",
    all_fields: "",
  });

  // Effect hook to fetch data when filterData changes
  useEffect(() => {
    const queryParams = formatDataToUrlQueryParams(filterData);
    fetchData(`?${queryParams}`);
  }, [filterData]);

  // Data fetching function
  const fetchData = (params) => {
    setLoading(true);
    setError(null);

    getArchives(params)
      .then((res) => {
        const formattedRows = res.data.data
          ? res.data.data.map((archive) => ({
              id: archive.id,
              picture_path: archive.article.picture_path,
              title: archive.article.title,
              subjects: archive.article.subjects,
              author: archive.article.author,
              summary: archive.summary,
              publishedAt: formatDate(archive.article.publication_date),
              status: archive.status,
              createdAt: archive.article.created_at,
              bookmarkingPath: archive.bookmarking_path,
              articleId: archive.article.id,

            }))
          : [];
        setArticleCount(res.data.count);
        setRows(formattedRows);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching archives:", error);
        setError("Failed to load data. Please try again.");
        setLoading(false);
      });
  };

  const getArchivesBySelectedDocument = async (id) => {
    try {
      const res = await getArchivesById(id);
      let data = res.data.data;

      return {
        title: data.article.title,
        author: data.article.author,
        filename: data.article.filename,
        extension: data.article.extension,
        publishedAt: data.article.publication_date,
        picture_path: data.article.picture_path,
        ocr_text: data.ocr_text,
        translated_text: data.translated_text,
        summary: data.summary,
        bullet_points_summary: data.bullet_points_summary,
        description: data.description,
        bookmarking_path: data.bookmarking_path
      };
    } catch (error) {
      console.error("Error fetching archives by id:", error);
    }
  };


  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  // Handle Enter key press in search input
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchSubmit();
    }
  };

  // Handle search submission
  const handleSearchSubmit = () => {
    // Reset all filters except the current one
    const updatedFilterData = {
      page: 1,
      size: 10,
      Title: "",
      Author: "",
      Date: "",
      Language: "",
      subjectsAndkeywords: "",
      all_fields: "",
    };

    // Map the current filter to the corresponding key in filterData
    const filterKeyMap = {
      "All Fields": "all_fields",
      Title: "title",
      Author: "author",
      Date: "date",
      Language: "language",
      "Subjects And Keywords": "subjectsAndkeywords",
    };

    const filterKey = filterKeyMap[currentFilter] || "all_fields";

    // Set the current filter value
    updatedFilterData[filterKey] = searchText.trim();

    setFilterData(updatedFilterData);
  };

  // Handle filter selection change
  const handleFilterChange = (event) => {
    setCurrentFilter(event.target.value);
  };

  // Pagination handlers
  const handlePageChange = (newPage) => {
    setFilterData((prev) => ({ ...prev, page: newPage }));
  };

  const handleRowsPerPageChange = (newSize) => {
    setFilterData((prev) => ({ ...prev, size: newSize, page: 1 }));
  };

  // Functions for digitizing articles
  const handleOpenDialog = () => {
    setOpenDigitizerDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDigitizerDialog(false);
  };

  const handleDigitizeArticle = (data) => {
    setIsDigitizing(true);
    digitize(data)
      .then((response) => {
        setNotification({
          show: true,
          snackbarMessage: "Article created successfully!",
          severity: "success",
        });
        setOpenDigitizerDialog(false);
        setIsDigitizing(false);
        setFilterData({ ...filterData, page: 1 });
      })
      .catch((error) => {
        setIsDigitizing(false);
        setNotification({
          show: true,
          snackbarMessage: "Failed to digitize the document. Please try again!",
          severity: "error",
        });
      });
  };
    const refreshArticles = () => {
        const queryParams = formatDataToUrlQueryParams(filterData);
        fetchData(`?${queryParams}`);
    };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={2} style={{ minHeight: "630px" }}>
        <SoftBox mb={2}>
          {/* Search and Filter Section */}
          <SoftBox mb={0.5} sx={{ marginRight:'8px', marginLeft:'8px', paddingTop: "30px"}}>
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={12} md={12}>
                <Box display="flex" alignItems="center">
                  {/* Search Input */}
                  <Box sx={{ flexGrow: 1}}>
                    <TextField
                        variant="outlined"
                        placeholder="Search..."
                        value={searchText}
                        onChange={handleSearchChange}
                        onKeyDown={handleKeyDown}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                              <InputAdornment position="end" sx={{marginLeft:"auto"}}>
                                <IconButton onClick={handleSearchSubmit} color="primary">
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                          ),
                        }}
                    />
                  </Box>

                  {/* 'Filter By' Dropdown */}
                  <Box sx={{ ml: 2 }}>
                    <Select
                      value={currentFilter}
                      onChange={handleFilterChange}
                      displayEmpty
                      variant="outlined"
                      inputProps={{ "aria-label": "Filter By" }}
                      style={{ minWidth: 150 }}
                    >
                      <MenuItem value="All Fields">All Fields</MenuItem>
                      <MenuItem value="Title">Title</MenuItem>
                      <MenuItem value="Author">Author</MenuItem>
                      <MenuItem value="Date">Date</MenuItem>
                      <MenuItem value="Language">Language</MenuItem>
                      <MenuItem value="Subjects And Keywords">
                        Subjects And Keywords
                      </MenuItem>
                    </Select>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </SoftBox>

          {/* Results Header and Action Button */}
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={1}
            style={{paddingTop: "16px", paddingBottom: "16px"}}
          >
            <SoftTypography variant="h6">
              {articleCount} results <MdRssFeed />
            </SoftTypography>
            {rolesAllowed.includes(role) && <SuiButton
              variant="gradient"
              color="primary"
              size="small"
              onClick={handleOpenDialog}
          >
            <IoIosAdd/> Digitize new document
          </SuiButton>}
          </SoftBox>

          {/* Error Message */}
          {error && (
            <SoftBox mb={2}>
              <SoftTypography color="error">{error}</SoftTypography>
            </SoftBox>
          )}

          {/* Loading Indicator or Data Display */}
          {loading ? (
            <SoftBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="200px"
            >
              <SoftTypography>Loading...</SoftTypography>
            </SoftBox>
          ) : (
            <SoftBox>
              <Grid>
                <CardComponent
                  data={rows}
                  count={articleCount}
                  size={filterData.size}
                  page={filterData.page}
                  handlePageChange={handlePageChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  loadingData={loading}
                  getArchivesBySelectedDocument={getArchivesBySelectedDocument}
                  setNotification={setNotification}
                  refreshArticles={refreshArticles}
                />
              </Grid>
            </SoftBox>
          )}
        </SoftBox>
      </SoftBox>
      <Footer />

      {/* Digitize Article Dialog */}
      {openDigitizerDialog && (
        <DigitizeArticleDialog
          open={openDigitizerDialog}
          isDigitizing={isDigitizing}
          onClose={handleCloseDialog}
          handleDigitizeArticle={handleDigitizeArticle}
        />
      )}

      {/* Snackbar Notification */}
      <SnackbarNotification
        notification={notification}
        setNotification={setNotification}
      />
    </DashboardLayout>
  );
};

export default publications;
