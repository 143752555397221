// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import DashboardLayout from "reusableComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "reusableComponents/Navbars/DashboardNavbar";
import Footer from "reusableComponents/Footer";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import BuildByDevelopers from "layouts/dashboard/components/BuildByDevelopers";
import WorkWithTheRockets from "layouts/dashboard/components/WorkWithTheRockets";
import {getStatistics} from "../../axios-client";
import {useEffect, useState} from "react";
import MiniStatisticsCard from "../../reusableComponents/Cards/StatisticsCards/MiniStatisticsCard";

import { PiBooksFill } from "react-icons/pi";
import { HiUsers } from "react-icons/hi2";
import { FaUniversity } from "react-icons/fa";
import DonutChart from "../../reusableComponents/Cards/StatisticsCards/DonutChart";



function Dashboard() {

    const [departmentsCount, setDepartmentCount] = useState(0);
    const [digitizationsCount, setDigitizationsCount] = useState(0);
    const [librariansCount, setLibrariansCount] = useState(0);
    const [researchersCount, setResearchersCount] = useState(0);
    const [universitiesCount, setUniversitiesCount] = useState(0);

    const fetchData = () => {
        getStatistics()
            .then(res => {
                setDepartmentCount(res.data.department_count);
                setDigitizationsCount(res.data.digitizations_count);
                setLibrariansCount(res.data.librarians_count);
                setResearchersCount(res.data.researchers_count);
                setUniversitiesCount(res.data.universities_count);
            })
            .catch(error => {
                    console.error('Error fetching statistics:', error);
                });
            }

    useEffect(() => {
        fetchData();
    }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

        <SoftBox py={3}>
            <SoftBox mb={3}>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
                <MiniStatisticsCard
                    title={{ text: "Total articles" }}
                    count={digitizationsCount}
                    // percentage={{ color: "success", text: "+55%" }}
                    icon={{ color: "primary", component: <PiBooksFill /> }}
                />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
                <MiniStatisticsCard
                    title={{ text: "Total reserachers" }}
                    count={researchersCount}
                    // percentage={{ color: "success", text: "+3%" }}
                    icon={{ color: "primary", component: <HiUsers /> }}
                />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
                <MiniStatisticsCard
                    title={{ text: "Total librarians" }}
                    count={librariansCount}
                    // percentage={{ color: "error", text: "-2%" }}
                    icon={{ color: "primary", component: <HiUsers /> }}
                />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
                <MiniStatisticsCard
                    title={{ text: "Total universities" }}
                    count={universitiesCount}
                    // percentage={{ color: "success", text: "+5%" }}
                    icon={{
                        color: "primary",
                        component: <FaUniversity />,
                    }}
                />
            </Grid>
        </Grid>
            </SoftBox>

      <SoftBox py={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={7}>
              <BuildByDevelopers departmentsCount={departmentsCount}
                                 digitizationsCount={digitizationsCount}
                                 librariansCount={librariansCount}
                                 researchersCount={researchersCount}
                                 universitiesCount={universitiesCount}

              />
            </Grid>
              <Grid item xs={12} lg={5}>
                  <DonutChart
                      researchersCount={researchersCount}
                      librariansCount={librariansCount}
                      universitiesCount={universitiesCount}
                      digitizationsCount={digitizationsCount}
                  />
              </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
