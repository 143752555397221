import React, { useState, useEffect } from 'react';
import {getDepartementsList, getResearchersActivity, getResearchersList, getUsersList} from "../../axios-client";
import Tables from "../../reusableComponents/Tables/Table/TableCard";
import SnackbarNotification from "../../reusableComponents/Snackbar/SnackbarNotification";
import AvatarComponent from "../../reusableComponents/Avatar/AvatarComponent";
import {getFormattedTodayDate} from "../../utlis/generalUtils";
import {useSoftUIController} from "../../context";
import {LIBRARIAN_ROLE} from "../../utlis/constant";

const Report = () => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [notification, setNotification] = useState({show: false, snackbarMessage: '', severity: ""})
    const [articles, setArticles] = useState([]);
    const [collapsed, setCollapsed] = useState(true);
    const today = getFormattedTodayDate();
    const [filterData, setFilterData] = useState({
        page: 1,
        size: 10,
        Department:"",
        Researcher:"",
        from: today,
        to: today
        })
    const [activitiesCount, setActivitiesCount] = useState(0);
    const [totalPagesCount, setTotalPagesCount] = useState(0);
    const [departments, setDepartments] = useState([{ id: "", name: "Select Department" }]);
    const [researchers, setResearchers] = useState([{ id: "", name: "Select User" }]);
    const [controller] = useSoftUIController();

    const {role} = controller;

    useEffect(() => {
        fetchData(filterData);
    }, [filterData]);

    useEffect(() => {
        let params = {};
        if (filterData.Department !== "") {
            params = { department_id: filterData.Department };
        }
        getUsersList(params)
            .then(res => {
                const defaultOption = [{ id: "", name: "Select User" }];
                setResearchers([...defaultOption, ...res.data.users]);
            }).catch(error => {
            console.error('Error fetching researchers');
        });
    }, [filterData.Department]);

    useEffect(() => {
        if (role && role !== LIBRARIAN_ROLE) {
            getDepartementsList()
                .then(res => {
                    const defaultOption = [{ id: "", name: "Select Department" }];
                    setDepartments([...defaultOption, ...res.data])
                }).catch(error => {
                console.error('Error fetching researchers');
            });
        }

    }, [role]);

    const handlePageChange = (newPage) => {

        setFilterData({...filterData, page: newPage});
    }
    const handleRowsPerPageChange = (newSize) => {

        setFilterData({...filterData, size: newSize, page: 1});
    }
    const handleFilterChange = (key, value) => {
        if (key === 'from' || key === 'to') {
            setFilterData({ ...filterData, from: value.from, to: value.to });
        } else {
            setFilterData({ ...filterData, [key]: value });
        }
    };

    const fetchData = (params) => {
        const queryParams = {
            page: params.page || 1,
            size: params.size || 10,
            department_id: params.Department !== "" ? params.Department : null,
            researcher_id: params.Researcher !== "" ? params.Researcher : null,
            from: params.from || null,
            to: params.to || null
        };

       getResearchersActivity(queryParams)
            .then(res => {
                const collapsedRows = [];
                const formattedRows = res.data.report.map(activity => {
                    return {
                        "#": activity.researcher_id,
                        "Researcher Name": (<AvatarComponent name={activity.researcher} image={activity.researcher_picture ?? activity.researcher}  bgColor={"secondary"} variant={"rounded"}/>),
                        "Department": activity.department,
                        "Total Articles": activity.number_of_articles,
                        "Total pages": activity.total_pages,
                    };

                });


                setRows(formattedRows);
                setActivitiesCount(res.data.total_researchers);
                setTotalPagesCount(res.data.total_pages)

                res.data.report.forEach(activity => {

                    collapsedRows[activity.researcher_id] = activity.articles.map(article => ({
                        "Title": article.article_title,
                        "Total pages": article.page_count,
                        "Digitized date": article.created_at,
                        "Action": "",
                    }))
                })


                setArticles(collapsedRows);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching activities:', error);
                setLoading(false);
            });
    }

    let filters = [];

    {
        role === LIBRARIAN_ROLE ?
            filters = [
                {
                    key: 'Researcher',
                    label: 'User',
                    type: 'select',
                    options: researchers.map(res => ({id: res.id, label: res.name}))
                },
                {key: 'from', label: 'Digitized date', type: 'date'}]
            :
            filters = [
                {
                    key: 'Department',
                    label: 'Department',
                    type: 'select',
                    options: departments.map(dep => ({id: dep.id, label: dep.name}))
                },
                {
                    key: 'Researcher',
                    label: 'User',
                    type: 'select',
                    options: researchers.map(res => ({id: res.id, label: res.name}))
                },
                {key: 'from', label: 'Digitized date', type: 'date'}
            ]
    }

    return (
        <>
            <Tables rows={rows} title={"Report"} loadingData={loading} collapsed={collapsed} collapsedRows={articles}
                    selector={"#"}
                    count={activitiesCount}
                    displayFooter={true}
                    footerRow={{key: "Total Pages", value: totalPagesCount}}
                    size={filterData.size}
                    page={filterData.page}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    filters={filters}
                    filterData={filterData}
                    handleFilterChange={handleFilterChange}
            />
            <SnackbarNotification
                notification={notification}
                setNotification={setNotification}
            />
        </>
    );
}
export default Report;