import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Divider } from '@mui/material';
import SuiButton from "../../components/SoftButton";
import { IoClose } from "react-icons/io5";
import { getSubjects, addSubject } from "../../axios-client";
import data from '../../constants/languages.json';
import "../../mainComponents/departments/assets/departmentsStyleSheet.css";
import CircularProgress from '@mui/material/CircularProgress';
import MetaData from "./components/MetaData";
import FileUpload from "./components/FileUpload";
import SubjectTerms from "./components/SubjectTerms";
import PropTypes from "prop-types";

const DigitizeArticleDialog = ({open, onClose, handleDigitizeArticle, isDigitizing}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [formData, setFormData] = useState({
        title: '',
        author: '',
        publicationDate: '',
        sourceLanguage: '',
        targetLanguage: '',
        subjectTerms: [],
        file: null,
    });
    const [errors, setErrors] = useState({});
    const [searchSubject, setSearchSubject] = useState('');
    const [isAddingSubject, setIsAddingSubject] = useState(false);

    const inputContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${isFocused ? '#cdcecf' : '#dcdfe3'}`,
        borderRadius: '8px',
        padding: '5px 10px',
        marginBottom: '10px',
        transition: 'border-color 0.3s ease',
    };

    const inputStyle = {
        width: '100%',
        padding: '10px 0px',
        border: 'none',
        fontSize: '14px',
        boxSizing: 'border-box',
        outline: 'none',
        fontFamily: 'Arial, Roboto',
    };

    useEffect(() => {
        setLanguages(data);
        getSubjects()
            .then(res => {
                setSubjects(res.data);
            })
            .catch(error => {
                console.error('Error fetching subjects:', error);
            });
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file && file.type !== 'application/pdf') {
            setErrors(prevErrors => ({ ...prevErrors, file: 'Only PDF files are allowed' }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, file: '' }));
            setFormData(prevState => ({
                ...prevState,
                file: file
            }));
        }
    };

    const validateForm = () => {
        let newErrors = {};
        if (!formData.title) newErrors.title = 'Title is required';
        if (!formData.author) newErrors.author = 'Author is required';
        if (!formData.publicationDate) newErrors.publicationDate = 'Publication date is required';
        if (!formData.sourceLanguage) newErrors.sourceLanguage = 'Source language is required';
        if (!formData.targetLanguage) newErrors.targetLanguage = 'Target language is required';
        if (!formData.file) newErrors.file = 'File upload is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleDigitization = () => {
        if (validateForm() && !isDigitizing) {

            const data = new FormData();

            data.append('title', formData.title);
            data.append('author', formData.author);
            data.append('publication_date', formData.publicationDate);
            data.append('source_language', formData.sourceLanguage);
            data.append('target_language', formData.targetLanguage);
            data.append('file', formData.file);

            formData.subjectTerms.forEach(subjectId => {
                data.append('subjects', subjectId);
            });

            handleDigitizeArticle(data);
        }
    };

    const handleAddNewSubject = () => {
        if (!searchSubject.trim()) return;

        setIsAddingSubject(true);
        addSubject({ name: searchSubject })
            .then(res => {
                return getSubjects();
            })
            .then(res => {
                setSubjects(res.data);
                const newSubjectId = res.data.find(subject => subject.name === searchSubject)?.id;
                if (newSubjectId) {
                    setFormData(prevState => ({
                        ...prevState,
                        subjectTerms: [...prevState.subjectTerms, newSubjectId]
                    }));
                }
                setSearchSubject('');
            })
            .catch(error => {
                console.error('Error adding subject:', error);
            })
            .finally(() => {
                setIsAddingSubject(false);
            });
    };

    return (
        <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth PaperProps={{ style: { borderRadius: 15 } }}>
            <DialogTitle
                id="form-dialog-title"
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
                Article Metadata
                <IoClose
                    aria-label="close"
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        cursor: 'pointer'
                    }}
                />
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: "0px 24px" }}>

                <MetaData
                    inputContainerStyle={inputContainerStyle}
                    inputStyle={inputStyle}
                    formData={formData}
                    handleInputChange={handleInputChange}
                    setIsFocused={setIsFocused}
                    errors={errors}
                    handleSelectChange={handleSelectChange}
                    languages={languages}
                />

                <SubjectTerms subjects={subjects} setSearchSubject={setSearchSubject} handleAddNewSubject={handleAddNewSubject}
                              isAddingSubject={isAddingSubject} searchSubject={searchSubject} formData={formData} setFormData={setFormData}
                />

                <FileUpload setFormData={setFormData} setErrors={setErrors}
                            handleFileUpload={handleFileUpload} formData={formData} errors={errors}
                />

            </DialogContent>
            <Divider />
            <DialogActions>
                <SuiButton variant="gradient" color="secondary" size="medium" onClick={onClose}>Close</SuiButton>
                <SuiButton variant="gradient" color="primary" size="medium" onClick={handleDigitization}>
                    {isDigitizing ? 'Digitizing...' : 'Digitize'}
                    {isDigitizing && <CircularProgress size={20} style={{ marginLeft: 10, color: "white" }} />}
                </SuiButton>
            </DialogActions>
        </Dialog>
    );
};

DigitizeArticleDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    handleDigitizeArticle: PropTypes.func,
    isDigitizing: PropTypes.bool
}
export default DigitizeArticleDialog;