import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import logo from "assets/images/logo.png";

// Soft UI Dashboard React examples
import PageLayout from "reusableComponents/LayoutContainers/PageLayout";
import Footer from "../Footer";

function CoverLayout({color = "info", header = "", title = "", description = "", image, logo, top = 8, children}) {
    return (
        <PageLayout background="white">
            <Grid
                container
                justifyContent="center"
            >
                <Grid item xs={11} sm={8} md={5} xl={3}>

                    <SoftBox mt={top}>
                        <SoftBox display="flex" ml={3}>
                            <img src={logo} alt="Logo" style={{ maxWidth: '40%' }} />
                        </SoftBox>
                        <SoftBox pt={3} px={3}>
                            {!header ? (
                                <>
                                    <SoftBox mb={1}>
                                        <SoftTypography variant="h3" fontWeight="bold" color={"primary"} textGradient>
                                            {title}
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftTypography variant="body2" fontWeight="regular" color="text">
                                        {description}
                                    </SoftTypography>
                                </>
                            ) : (
                                header
                            )}
                        </SoftBox>
                        <SoftBox p={3}>{children}</SoftBox>
                    </SoftBox>
                </Grid>
                <Grid item xs={12} md={5}>
                    <SoftBox
                        height="100%"
                        display={{xs: "none", md: "block"}}
                        position="relative"
                        right={{md: "-12rem", xl: "-16rem"}}
                        mr={-16}
                        sx={{
                            transform: "skewX(-10deg)",
                            overflow: "hidden",
                            borderBottomLeftRadius: ({borders: {borderRadius}}) => borderRadius.lg,
                        }}
                    >
                        <SoftBox
                            ml={-8}
                            height="100%"
                            sx={{
                                backgroundImage: `url(${image})`,
                                backgroundSize: "cover",
                                transform: "skewX(10deg)",
                            }}
                        />
                    </SoftBox>
                </Grid>
            </Grid>
            <Footer />
        </PageLayout>
    );
}

CoverLayout.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
    ]),
    header: PropTypes.node,
    title: PropTypes.string,
    logo: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string.isRequired,
    top: PropTypes.number,
    children: PropTypes.node.isRequired,
};

export default CoverLayout;
